<!--
 * @Author: lzh
 * @Date: 2022-07-16 16:30:14
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-10 10:31:01
 * @Description: file content
-->
<template>
  <div class="tabs-box px-tabs-box">
    <el-tabs
      v-model="activeName"
      class="top-tabs"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="(item, index) in configs.items"
        :key="index"
        :label="item[`${nowL}title`] || item.title"
        :name="index + ''"
      >
        <div v-if="index == 0">
          <selfCompanyIntroduction
            :Xindex="Xindex"
            :tabIndex="index"
            :configs="item.configs"
            @clickItem="clickItem"
          ></selfCompanyIntroduction>
        </div>
        <div v-else-if="index == 1">
          <selfDevHistory
            :Xindex="Xindex"
            :tabIndex="index"
            @clickItem="clickItem"
            :configs="item.configs"
          ></selfDevHistory>
        </div>
        <div v-else-if="index == 2">
          <!-- <selfPartner
            :Xindex="Xindex"
            :tabIndex="index"
            @clickItem="clickItem"
            :configs="item.configs"
          ></selfPartner> -->
          <selfImage
            :Xindex="index"
            @clickItem="clickItem('src',2)"
            :configs="item.configs"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
import selfCompanyIntroduction from './companyIntroduction.vue';
import selfDevHistory from './devHistory.vue';
// import selfPartner from './partner.vue';
import selfImage from "./image.vue";
export default {
  name: 'self-tabs',
  mixins: [langMixin],
  components: {
    selfCompanyIntroduction,
    selfDevHistory, selfImage
    // selfPartner,
  },
  props: {
    configs: {
      default () {
        return {
          title: '',
          desc: '',
          items: [
            {
              title: '公司介紹',
              configs: {
                title: '薑軟件有限公司',
                desc: 'Gingersoft Limited',
                icon: require('../../assets/material/關於Gingersoft/dingwei-icon.png'),
                img: require('../../assets/material/關於Gingersoft/gongsi-img.png'),
                address: '中國·香港',
                addressDetail: '香港九龍青山道700號時運中心5樓4室',
                logo: require('../../assets/material/關於Gingersoft/Gingersoft-logo 2.png'),
                logoDesc:
                  'Gingersoft薑軟件在2012年於香港成立，多年來一直専注於研究及開發餐飲管理系統，擁有豐富的行業經驗及服務多所小型、大型及連鎖餐廳，致力為每位客戶提供客製化的智能餐飲管理方案，務求為客戶節省人力成本、提高經營效率！我們更擁有自己的研發團隊，能及時有效地因應社會發展需要為每位客戶設計出最合適的產品！',
                manageImage: require('../../assets/material/關於Gingersoft/jingying-img.png'),
                manageTitle: '經營理念',
                manageDesc:
                  '清楚知道餐飲業面臨“租金高 人工高 食材成本高 利潤低”的三高一低痛點，因應全球發展趨勢各行各業的戰場亦從線下實體店已經延伸至線上外賣店。為了符合時代發展需要，創新推出雲端餐飲管理系統，結合掃碼落單及會員系統，助每位老闆從經營到營銷到營利！',
              },
            },
            {
              title: '發展歷程',
              configs: {
                items: [
                  {
                    yyyy: '2022年',
                    desc: '現在',
                  },
                  {
                    yyyy: '2021年',
                    desc: '雲端餐飲管理系統RicePOS與會計系統 Xero完成技術對接業務拓展至新加坡',
                  },
                  {
                    yyyy: '2020年',
                    desc: '與著名物流系統、電子支付系統完成技術對接',
                  },
                  {
                    yyyy: '2027年',
                    desc: '全港首創固定碼點餐，建立會員系統 推出飲食著數APP“飯糰”',
                  },
                  {
                    yyyy: '2013年',
                    desc: '我們擁有了第一個合作夥伴',
                  },
                  {
                    yyyy: '2012年',
                    desc: '香港成立 Gingersoft',
                  },
                ],
              },
            },
            {
              src: require('../../assets/do-img/長圖.png'),
              fit: 'contain',
              alt: '',
              phoneSrc: '',
              go: '',
            }
            // {
            //   title: '合作夥伴',
            //   configs: {
            //     items: [
            //       {
            //         img: require('../../assets/material/合作夥伴/01@2x.png'),
            //       },
            //       {
            //         img: require('../../assets/material/合作夥伴/02@2x.png'),
            //       },
            //       {
            //         img: require('../../assets/material/合作夥伴/03@2x.png'),
            //       },
            //       {
            //         img: require('../../assets/material/合作夥伴/04@2x.png'),
            //       },
            //       {
            //         img: require('../../assets/material/合作夥伴/05@2x.png'),
            //       },
            //       {
            //         img: require('../../assets/material/合作夥伴/06@2x.png'),
            //       },
            //       {
            //         img: require('../../assets/material/合作夥伴/07@2x.png'),
            //       },
            //     ],
            //   },
            // },
          ],
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      activeName: '0',
    };
  },
  methods: {
    handleClick (tab, event) {
      // console.log(tab, event);
    },
    clickItem (...e) {
      console.log('e', ...e);
      this.$emit('clickItem', ...e);
    },
  },
};
</script>

<style lang="less" scoped>
.top-tabs {
  /deep/ .el-tabs__nav {
    transform: translateX(20vw) !important;
  }
  & /deep/ .el-tabs__item {
    height: 66px;
    line-height: 66px;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    padding: 0 50px;
    &:hover {
      color: var(--themeColor);
    }
  }
  & /deep/ .el-tabs__active-bar {
    background-color: var(--themeColor);
  }
  & /deep/ .is-active {
    color: var(--themeColor);
  }
}
@media screen and (max-width: 1000px) {
  .px-tabs-box {
    .top-tabs {
      & /deep/ .el-tabs__nav-wrap {
        padding: 0;
        .el-tabs__nav {
          transform: translateX(0) !important;
          .el-tabs__item {
            padding: 10px 0 10px 35px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            height: auto;
            line-height: inherit;
          }
        }
      }
    }
  }
}
</style>
